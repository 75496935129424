export const targetConfig = {
  TARGET: 'fyle',
  IS_FYLE_ENV: true,
  IS_VIRTUAL_CARDS_ENABLED: false,
  WEBPAGE_TITLE: 'Fyle: Never lose track',
  BRAND_NAME: 'Fyle',
  FAVICONS_PATH: 'assets/images/favicon/fyle',
  LOGO_URL: 'assets/images/icons/fyle/fyle_logo.png',
  FULL_LOGO_URL_WHITE: 'assets/images/icons/fyle/fyle_logo_white_full.png',
  FULL_LOGO_URL_BLACK: 'assets/images/icons/fyle/fyle_logo_black_full.png',
  SIDENAV_FULL_LOGO_URL: 'assets/images/icons/fyle/fyle_logo_white_full.png',
  COLORS: { brandPrimary: '#ff3366', adminSpendOverviewChart: '#ff7eb3' },
  SUPPORT_EMAIL: 'support@fylehq.com',
  RECEIPTS_EMAIL: 'receipts@fylehq.com',
  HELP_ARTICLES_DOMAIN: 'https://www.fylehq.com/help',
  IMAGES_PATH: 'assets/images/fyle',
  APP_V2_IMAGES_PATH: 'ng-assets/images/fyle',
  ADD_NEW_USER_IMG: 'assets/icons/png/fy-add-single-user.png',
  GENERIC_ZERO_STATE_IMG: 'assets/images/fyle/zero-state.png',
  FILTER_ZERO_STATE_IMG: 'assets/images/expense-filter-zero-state-new.svg',
  RECEIPT_ZERO_STATE_IMG: 'assets/images/expense-zero-state-receipt.svg',
  TOOLTIP_MESSAGE_FOR_DISABLING_CURRENCY: 'Home Currency cannot be changed once expenses are created.',
  SVG_ICONS_PATH: 'assets/icons/svg/fyle',
  // TODO: Move to libs and use as common assets
  SMS_DIALOG_BACKGROUND_VIDEO_PATH: 'assets/videos/sms-dialog-background.mp4',
  SMS_DIALOG_BACKGROUND_VIDEO_THUMBNAIL_PATH: 'assets/images/sms-dialog-background-thumbnail.png',
};
